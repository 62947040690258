import { BrowserRouter, Route, Routes } from "react-router-dom"

import DashboardPage from "./pages/DashboardPage"
import AdminUserListPage from "./pages/AdminUserListPage"
import WorkPage from "./pages/WorkPage/WorkPage"
import AdminUserAddPage from "./pages/AdminUserAddPage"
import Layout from "./components/Layout"
import AdminUserReportPage from "./pages/AdminUserReportPage"

// TODO: add a 404 page
// TODO: protect admin routes

function AppRoutes() {

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />} >
                    <Route path="/"                     element={<DashboardPage       />} />
                    <Route path="/work"                 element={<WorkPage            />} />
                    <Route path="/admin/users/"         element={<AdminUserListPage   />} />
                    <Route path="/admin/users/add"      element={<AdminUserAddPage    />} />
                    <Route path="/admin/users/report"   element={<AdminUserReportPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes
