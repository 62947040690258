import { Container, Dropdown, Nav, Navbar, NavItem, NavLink } from "react-bootstrap"
import DropdownItem from "react-bootstrap/DropdownItem"
import DropdownMenu from "react-bootstrap/DropdownMenu"
import DropdownToggle from "react-bootstrap/DropdownToggle"
import IUser from "data/IUser"

interface AppMenuProps {
    user:    IUser
    logout:  () => void
}

const AppMenu = (props: AppMenuProps) => {
    let adminLinks = <></>
    if (props.user.isAdmin) {
        adminLinks =
            <>
                <Nav.Link href="/admin/users/">User List</Nav.Link>
                <Nav.Link href="/admin/users/report">User Report</Nav.Link>
            </>
    }
    const userInfo =
        <Dropdown as={NavItem}>
            <DropdownToggle as={NavLink}>
                Signed in as {props.user.displayName}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem link onClick={ () => { props.logout() }}>
                    Sign Out
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>

    return (
        <Navbar bg="dark" className="navbar-dark bg-dark nav" expand="sm" style={{minHeight: "8vh"}}>
            <Container>
                <Navbar.Brand href="/">
                    V A D E &nbsp;/ &nbsp;Helios
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <Nav.Link href="/">Dashboard</Nav.Link>
                        {adminLinks}
                        {userInfo}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default AppMenu