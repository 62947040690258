import Table from 'react-bootstrap/Table'
import IUserWithStats from "data/IUserWithStats"
import TableRow from "./TableRow/TableRow"

interface UserReportProps {
    usersWithStats: IUserWithStats[]
}

const UserReport = ({usersWithStats}: UserReportProps) => {
    return (
        <div className="UserReport">
            <h1>User Report</h1>
            <Table striped bordered hover className="my-5">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Email</th>
                        <th>Last Active</th>
                        <th>Total # Completed</th>
                        <th>Median Time (s)</th>
                        <th>Median Time (s) [Last 100]</th>
                        <th>Average Time (s) [Last 100]</th>
                        <th>Total Correct</th>
                        <th>Total Incorrect</th>
                        <th>Accuracy Score</th>
                    </tr>
                </thead>
                <tbody>
                    {usersWithStats.map(user =>
                        <TableRow key={user.userId} userWithStats={user}/>
                    )}
                </tbody>
            </Table>
        </div>
    )
}

export default UserReport