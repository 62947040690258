import IRecentUserStatsResp from "api/responses/IRecentUserStatsResp"

export default interface IRecentUserStats {
    medianTime: number
    average: number
}

export function statsFromApi( user: IRecentUserStatsResp ) : IRecentUserStats {
    return {
        medianTime:    user.median_time / 1000,
        average:       user.average / 1000
    }
}