import ITraceResp from "api/responses/ITraceResp"

export default interface ITrace {
    traceId:            string
    originalImageUrl:   string
    timeCaptured:       string
}

export const traceFromApi = (apiTracesResponse: ITraceResp[]): ITrace[] =>
    apiTracesResponse.map(answer => ({
            traceId:            answer.trace_id,
            originalImageUrl:   answer.original_image_url,
            timeCaptured:       answer.time_captured
        }))
