import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { collection, CollectionReference, DocumentData, Firestore, getFirestore } from "firebase/firestore"

export default class VadeFirebase {
    private readonly devConfig = {
        apiKey: "AIzaSyD_fvPT6i9Qbz7h9jFL1qMytRsuEhVX2sA",
        authDomain: "helios-dev-6b448.firebaseapp.com",
        projectId: "helios-dev-6b448",
        storageBucket: "helios-dev-6b448.appspot.com",
        messagingSenderId: "143013662758",
        appId: "1:143013662758:web:9d5de3bdfebbd3501996e7"
    }

    private readonly prodConfig = {
        apiKey: "AIzaSyDNxZJHkdt0T7Awxe1C8EqZnWymLVEQ9kU",
        authDomain: "helios-e9947.firebaseapp.com",
        projectId: "helios-e9947",
        storageBucket: "helios-e9947.appspot.com",
        messagingSenderId: "604111820363",
        appId: "1:604111820363:web:953a54c5be9c293e62dfce"
    }

    //cfg firebase ui
    public readonly uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/signedIn',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: () => false, //avoid redirects after sign in
        },
    }

    readonly app: firebase.app.App
    readonly db: Firestore
    readonly users: CollectionReference<DocumentData>

    isDev(): boolean {
        const url = window.location.href
        if (url.includes("localhost")) {
            return true
        }
        return url.includes("dev")
    }

    constructor() {
        if (this.isDev()) {
            this.app = firebase.initializeApp(this.devConfig)
        } else {
            this.app = firebase.initializeApp(this.prodConfig)
        }
        this.db = getFirestore(this.app)
        this.users = collection(this.db, "heliosUser")
    }
}

