import IUserStatsResp from "../api/responses/IUserStatsResp"
import { DateTime } from "luxon"
import DateUtil from "api/DateUtil"

export default interface IUserWithStats {
    userId: number
    email: string
    count: number
    medianTime: number
    lastActive: DateTime
}

export const usersFromApi = (apiUsersResponse: IUserStatsResp[]): IUserWithStats[] =>
    apiUsersResponse.map(user => (
        {
            userId: user.user_id,
            email: user.email,
            count: user.count,
            medianTime: (user.median_time / 1000),
            lastActive: DateUtil.parseUtc(user.last_active)
        }
    ))
