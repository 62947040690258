import { DateTime } from "luxon"

export default class DateUtil {
    static parseUtc(dateStr: string): DateTime {
        let parsedDate: DateTime = DateTime.fromISO(dateStr, {zone: 'utc'})
        if (!parsedDate.isValid) {
            parsedDate = DateTime.fromFormat(dateStr, "yyyy-MM-dd HH:mm:ss", {zone: 'utc'})
        }
        return parsedDate
    }
}