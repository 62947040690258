import { useEffect, useState } from "react"
import Api from "api/Api"
import FrameViewer, { IFrameObj } from "./FrameViewer"
import { Card } from "react-bootstrap"
import Trace from "data/ITrace"
import DateUtil from "api/DateUtil"
import "./QuestionViewer.css"

interface QuestionViewerProps {
    traceId:        string
    event:          string
    time:           string
    bbox:           Array<number[]>
    groundPlane:    Array<number[]>
    domain:         string
    classLabel:     string
    cameraId:       string
    answer:       boolean | null
    timezone:       string | null
}

export default function QuestionViewer(props: QuestionViewerProps) {
    const [t1, setT1] = useState<Trace>()
    const [t2, setT2] = useState<Trace>()

    function getFrameObj(label: string,
                         traceId: string,
                         timeCaptured: string,
                         props: QuestionViewerProps): IFrameObj {
        return {
            label: label,
            traceId: traceId,
            timeCaptured: DateUtil.parseUtc(timeCaptured),
            bbox: props.bbox,
            classLabel: props.classLabel,
            groundPoly: props.groundPlane,
        }
    }

    useEffect(() => {
        const offset = props.event === "start" ? -2 : 2

        Api.getTracesByOffset(props.cameraId, props.traceId, offset)
            .then(traces => {
                setT1(traces[0])
                setT2(traces[1])
            })
    }, [props.traceId])

    if (!(t1 && t2)) {
        return <>Loading Question</>
    }

    const f0 = getFrameObj("1", props.traceId, props.time, props)
    const f1 = getFrameObj("2", t1.traceId, t1.timeCaptured, props)
    const f2 = getFrameObj("3", t2.traceId, t2.timeCaptured, props)
    const frames = [f0, f1, f2]

    return (
        <div className="QuestionViewer">

            <Card className="text-right">
                <Card.Header as="h4"> Did a vehicle leave the parking space?</Card.Header>
                <Card.Subtitle className="mt-4 ms-4 text-muted"> If you cannot see the vehicle (if it's blocked by
                    another car), assume it is not there </Card.Subtitle>
                <Card.Body className="ps-4">
                    <strong>Rules:</strong> <br/>
                    1. If same vehicle is in all three boxes choose Deny <br/>
                    2. There must be a vehicle in green box in frame 1. If not, choose Deny<br/>
                    3. The same vehicle should NOT be in frame 2 OR 3. If it is in <strong>either</strong>, choose
                    Deny <br/>
                </Card.Body>
            </Card>

            <div className="columns">
                <div>
                    <FrameViewer className="frame" obj={frames[0]} color="#3dff3d"/>
                </div>
                <div>
                    <FrameViewer className="frame" obj={frames[1]} color="red"/>
                </div>
                <div>
                    <FrameViewer className="frame" obj={frames[2]} color="red"/>
                </div>
            </div>
        </div>
    )
}
