import React, { useEffect, useState } from 'react'
import IUserWithStats from "../../../data/IUserWithStats"
import Api from "../../../api/Api"
import IRecentUserStats from "../../../data/IRecentUserStats"

import './TableRow.css'
import IUserAccuracy from "../../../data/IUserAccuracy"

const GOOD_THRESHOLD = 3.5      // seconds
const WARNING_THRESHOLD = 4.5   // seconds

const GOOD_ACCURACY = 90        // percent
const WARNING_ACCURACY = 80     // percent

interface TableRowProps {
    userWithStats: IUserWithStats
}

const TableRow = ({userWithStats}: TableRowProps) => {

    const [recentStats, setRecentStats] = useState<IRecentUserStats | null>()
    const [accuracyStats, setAccuracyStats] = useState<IUserAccuracy | null>()
    const [isLoading, setIsLoading] = useState(true)

    enum StatClass {
        POOR = 'poor',
        WARNING = 'warning',
        GOOD = 'good',
        UNKNOWN = ''
    }

    function getStatCSS(timeTaken: number | undefined) : StatClass {
        if (!timeTaken) return StatClass.UNKNOWN
        else if (timeTaken <= GOOD_THRESHOLD) return StatClass.GOOD
        else if (timeTaken <= WARNING_THRESHOLD) return StatClass.WARNING
        else return StatClass.POOR
    }

    function getAccuracyCSS(accuracy: number | undefined) : StatClass {
        if (!accuracy) return StatClass.UNKNOWN
        else if (accuracy >= GOOD_ACCURACY) return StatClass.GOOD
        else if (accuracy >= WARNING_ACCURACY) return StatClass.WARNING
        else return StatClass.POOR
    }

    useEffect(() => {
        async function getAdditionalStats() {
            const stats = await Api.getRecentUserStats(userWithStats.userId)
            setRecentStats(stats)
            const accuracyStats = await Api.getUserAccuracy(userWithStats.userId)
            setAccuracyStats(accuracyStats)
            setIsLoading(false)
        }
        getAdditionalStats()
    }, [])

    return (
        <tr className="TableRow" data-testid="user-report-table-row">
            <td>{userWithStats.userId}</td>
            <td>{userWithStats.email}</td>
            <td>{userWithStats.lastActive.toRelative()}</td>
            <td>{userWithStats.count}</td>
            <td>{userWithStats.medianTime.toFixed(2)}</td>

            {isLoading ?
                <>
                    <td data-testid="loading-placeholder">Loading...</td>
                    <td data-testid="loading-placeholder">Loading...</td>
                    <td data-testid="loading-placeholder">Loading...</td>
                    <td data-testid="loading-placeholder">Loading...</td>
                    <td data-testid="loading-placeholder">Loading...</td>
                </> :
                <>
                    <td className={getStatCSS(recentStats?.medianTime)}>
                        {recentStats?.medianTime.toFixed(2)}
                    </td>
                    <td className={getStatCSS(recentStats?.average)}>
                        {recentStats?.average.toFixed(2)}
                    </td>
                    <td>
                        {accuracyStats?.totalCorrect}
                    </td>
                    <td>
                        {accuracyStats?.totalIncorrect}
                    </td>
                    <td className={getAccuracyCSS(accuracyStats?.total)}>
                        { accuracyStats!.total > 0 ?
                            (100 * accuracyStats!.totalCorrect / accuracyStats!.total).toFixed(0)
                            : "-"
                        }
                    </td>
                </>
            }

        </tr>
    )
}
export default TableRow