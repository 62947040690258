import React, { useEffect, useState } from 'react'
import IUserWithStats from "data/IUserWithStats"
import Api from "api/Api"
import UserReport from "components/UserReport/UserReport"

const AdminUserReportPage = () => {

    const [data, setData] = useState<IUserWithStats[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        Api.getUserStats()
            .then(users => {
                setData(users)
                setIsLoading(false)
            })
    }, [])

    return (
        <div className="container">
            {isLoading ? <h1 data-testid="loading-message">Loading</h1> : <UserReport usersWithStats={data}/>}
        </div>
    )
}

export default AdminUserReportPage