import IUserAccuracyResp from "api/responses/IUserAccuracyResp"

export default interface IUserAccuracy {
    totalCorrect: number
    totalIncorrect: number
    total: number
}

export function accuracyStatsFromApi( user: IUserAccuracyResp ) : IUserAccuracy {
    return {
        totalCorrect:    user.total_correct,
        totalIncorrect:  user.total_incorrect,
        total:           user.total_incorrect + user.total_correct
    }
}