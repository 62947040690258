import { IAnswerResp } from "api/responses/IAnswerResp"

export default interface IAnswer {
    questionId:      number
    questionType:    string
    stayId:          number
    answerId:        number
    cameraId:        string
    laneId:          string
    tz:              string
    lockedBy:        number
    lockedUntil:     string
    bbox:            Array<number[]>
    groundPlane:     Array<number[]>
    classLabel:      string
    startTrace:      string
    endTrace:        string
    startTimeUtc:    string
    endTimeUtc:      string
    startTimeLocal:  string
    endTimeLocal:    string
    answer:          boolean
}

export const answersFromApi = (answersResponse: IAnswerResp[]): IAnswer[] =>
    answersResponse.map(answer => (
        {
            questionId:      answer.question_id,
            questionType:    answer.question_type.toString(),
            stayId:          answer.stay_id,
            answerId:        answer.answer_id,
            cameraId:        answer.camera_id,
            laneId:          answer.lane_id,
            tz:              answer.tz,
            lockedBy:        answer.locked_by,
            lockedUntil:     answer.locked_until,
            bbox:            answer.bbox,
            groundPlane:     answer.ground_plane,
            classLabel:      answer.class_label.toString(),
            startTrace:      answer.start_trace,
            endTrace:        answer.end_trace,
            startTimeUtc:    answer.start_time_utc,
            endTimeUtc:      answer.end_time_utc,
            startTimeLocal:  answer.start_time_local,
            endTimeLocal:    answer.end_time_local,
            answer:          answer.answer
        }
    ))
