import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import Api from "api/Api"
import IUser from "data/IUser"

const AdminUserListPage = () => {
    const [users, setUsers] = useState<IUser[]>([])

    useEffect(() => {
        Api.getUsers(1000, 1)
            .then(users => setUsers(users))
    }, [])

    const userTable =
        <table className="table table-striped w-100">
            <thead>
            <tr>
                <td>User Id</td>
                <td>Display Name</td>
                <td>Email</td>
                <td>Admin?</td>
                <td>Created By</td>
                <td>Created When</td>
            </tr>
            </thead>
            <tbody>
            {users.map(user => <tr key={user.userId} data-testid="admin-user-list-row">
                <td>
                    {user.userId}
                </td>
                <td>
                    {user.displayName}
                </td>
                <td>
                    {user.email}
                </td>
                <td>
                    {user.isAdmin ? "Yes" : "No"}
                </td>
                <td>
                    {user.createdBy}
                </td>
                <td>
                    {user.createdWhen}
                </td>
            </tr>)}
            </tbody>
        </table>

    return (
        <div className="container">
            <h1>User List</h1>
            {userTable}
            <Link to="/admin/users/add"><Button>Add User</Button></Link>
        </div>
    )
}

export default AdminUserListPage