const FORCE_USE_PROD = false

export default class ApiUrl {

    static detectServ(url: string): string {
        if (FORCE_USE_PROD || url.includes("helios.vade.ai") || url.includes("helios-e9947") || url.includes("prod")) {
            return 'https://us-central1-vade-prod.cloudfunctions.net/vade_crud_api'
        }
        return 'https://us-central1-vade-dev-342822.cloudfunctions.net/vade_crud_api'
    }

    static detectImageServ(url: string): string {
        if (FORCE_USE_PROD || url.includes("helios.vade.ai") || url.includes("helios-e9947") || url.includes("prod")) {
            return 'https://storage.googleapis.com/trace_images_prod'
        }
        return 'https://storage.googleapis.com/trace_images'
    }

    static readonly serv = this.detectServ(window.location.href)
    static readonly imageServ = this.detectImageServ(window.location.href)

    // Users
    static readonly login = `${this.serv}/user/login`

    static getUsers = (pgSize: number, pgNum: number) =>
        `${this.serv}/user/get_users?page_size=${pgSize}&page_number=${pgNum}`

    static readonly createUser = `${this.serv}/user/create_user`

    static readonly userStats = `${this.serv}/helios/get_annotator_stats`

    static userRecentStats = (userId: number) => `${this.serv}/helios/get_annotator_recent_stats?user_id=${userId}`

    static userAccuracy = (userId: number) => `${this.serv}/helios/get_annotator_accuracy?user_id=${userId}`

    // Images for Frames
    static traceImgOriginal = (traceId: string) => `${this.imageServ}/${traceId}_original.jpg`

    // Tasks
    static readonly deploymentStats = `${this.serv}/helios/get_task_stats`

    static readonly saveAnswer =`${this.serv}/helios/save_answer`

    static readonly work = `${this.serv}/helios/get_work`

    static readonly getTracesByOffset =`${this.serv}/trace/get_traces_by_offset`


}