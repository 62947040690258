import { IDeploymentStatResp } from "api/responses/IDeploymentStatsResp"

export interface IDeploymentStat {
    uuid:                string
    name:                string
    activeUsers:         number
    checkedOut:          number
    incomplete:          number
    conflicts:           number
    complete:            number
    total:               number
}

export function statFromApi( stat: IDeploymentStatResp ): IDeploymentStat {
    return {
        uuid:             stat.uuid,
        name:             stat.name,
        activeUsers:      stat.active_users,
        checkedOut:       stat.checked_out,
        incomplete:       stat.incomplete,
        conflicts:        stat.conflicts,
        complete:         stat.complete,
        total:            stat.total
    }
}