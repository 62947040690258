import { Field, Form as FormikForm, Formik } from "formik"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import Api from "api/Api"
import IUser from "data/IUser"
import UserContext from "UserContext"

const AdminUserAddPage = () => {
    const user  = useContext(UserContext) as IUser
    const nav   = useNavigate()

    function onSubmit(values: any) {
        values["created_by"] = user.userId //required
        Api.createUser(values).then(_ => nav("/admin/users/"))
    }

    return (
        <Formik initialValues={{
            email: "",
            display_name: "",
            is_admin: false
        }} onSubmit={onSubmit}>
            <FormikForm>
                <div style={{display: "flex", flexDirection: "column", rowGap: "1em", width: "25%"}}>
                    <div>
                        <div>Email</div>
                        <div><Field className="form-control" name="email" type="text"/></div>
                    </div>
                    <div>
                        <div>Display Name</div>
                        <div><Field className="form-control" name="display_name" type="text"/></div>
                    </div>
                    <div>
                        <div>Is Admin <Field className="form-check-input" name="is_admin" type="checkbox"/></div>
                    </div>
                    <div>
                        <div></div>
                        <div><input className="form-control button text-white bg-primary" type="submit" value="Add User"/>
                        </div>
                    </div>
                </div>
            </FormikForm>
        </Formik>
    )
}

export default AdminUserAddPage