import { IUserResp } from "api/responses/IUserResp"


export default interface IUser {
    userId:      number;
    email:       string;
    displayName: string;
    isAdmin:     boolean;
    createdBy:   string;
    createdWhen: string;
}

export function userFromApi( user: IUserResp ) : IUser {
    return {
        userId:      user.user_id,     
        email:       user.email,       
        displayName: user.display_name,
        isAdmin:     user.is_admin,    
        createdBy:   user.created_by,  
        createdWhen: user.created_when
    };
}