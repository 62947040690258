import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

import firebase from "firebase/compat/app"
import { getAuth } from "firebase/auth"

import IUser from "data/IUser"
import Api from "api/Api"
import VadeFirebase from "api/VadeFirebase"
import AppLogin from "./AppLogin"
import AppMenu from "./AppMenu"
import UserContext from "UserContext"

const Layout = () => {

    const [loaded, setLoaded]               = useState<boolean>(false)
    const [firebaseUser, setFirebaseUser]   = useState<firebase.User>()
    const [user, setUser]                   = useState<IUser>()

    useEffect(() => {
        const unregAuthObserver = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setFirebaseUser(user)
                return
            }
            setLoaded(true)
        })
        //make sure we un-register firebase observers when the component unmounts.
        return () => unregAuthObserver()
    }, [])

    async function getUserData(email: string) {
        const user = await Api.login(email)
        setUser(user)
        setLoaded(true)
    }

    useEffect(() => {
        if (!firebaseUser?.email) {
            return
        }
        getUserData(firebaseUser.email!)
    }, [firebaseUser])

    const vadeFirebase = new VadeFirebase()

    function logout() {
        getAuth().signOut()
        setUser(undefined)
    }

    if (!loaded) {
        return <></>
    }
    if (!user) {
        return <AppLogin uiConfig={vadeFirebase.uiConfig} firebaseAuth={firebase.auth()}/>
    }

    return (
        <UserContext.Provider value={user}>
            <AppMenu user={user} logout={() => logout()} />
            <div className="m-4 full-height">

                <Outlet />

            </div>
        </UserContext.Provider>
    )
}

export default Layout

