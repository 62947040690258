import ApiUrl from "./ApiUrl"
import { answersFromApi } from "../data/IAnswer"
import { IUsersResp } from "./responses/IUserResp"
import { userFromApi } from "../data/IUser"
import ILoginResp from "./responses/ILoginResp"
import { IDeploymentStatsResp } from "./responses/IDeploymentStatsResp"
import { statFromApi } from "../data/IDeploymentStat"
import { traceFromApi } from "../data/ITrace"
import { usersFromApi } from "../data/IUserWithStats"
import { statsFromApi } from "../data/IRecentUserStats"
import { accuracyStatsFromApi } from "../data/IUserAccuracy"

export default class Api {
    private static postArgs(payload: any) {
        return {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload)
        }
    }

    static login(email: string) {
        let payload = {email: email}
        return fetch(ApiUrl.login, this.postArgs(payload))
            .then(resp => resp.json())
            .then((json: ILoginResp) => userFromApi(json.user))
    }

    static getUsers(pgSize: number, pgNum: number) {
        return fetch(ApiUrl.getUsers(pgSize, pgNum))
            .then(resp => resp.json())
            .then((json: IUsersResp) => json.users.map(u => userFromApi(u)))
    }

    static createUser(user: any) {
        return fetch(ApiUrl.createUser, this.postArgs(user))
    }

    static getWork(userId: number, escalated: boolean) {
        let payload = {user_id: userId, escalated: escalated}
        return fetch(ApiUrl.work, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload)
        })
            .then(resp => resp.json())
            .then(json => answersFromApi(json.answers))
    }

    static getDeploymentStats() {
        return fetch(ApiUrl.deploymentStats)
            .then(resp => resp.json())
            .then((json: IDeploymentStatsResp) => json.stats.map(s => statFromApi(s)))
    }

    static getTracesByOffset(cameraId: string,
                             startTrace: string,
                             offset: number) {
        let payload = {
            camera_uuid: cameraId,
            start_trace: startTrace,
            offset: offset
        }
        return fetch(ApiUrl.getTracesByOffset, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload)
        })
            .then(r => r.json())
            .then(json => traceFromApi(json.traces))
    }

    static saveAnswer(completedBy: number,
                      answerId: number,
                      timeTaken: number,
                      answer: boolean) {
        let payload = {
            completed_by: completedBy,
            answer_id: answerId,
            time_taken: timeTaken,
            answer: answer
        }
        return fetch(ApiUrl.saveAnswer, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload)
        })
    }

    static getUserStats() {
        return fetch(ApiUrl.userStats)
            .then(response => {
                if (!response.ok) throw new Error(`HTTP Error ${response.status}`)
                return response.json()
            })
            .catch(err => console.log(err))
            .then(rawData => usersFromApi(rawData.users))
    }

    static getRecentUserStats(userId: number) {
        return fetch(ApiUrl.userRecentStats(userId))
            .then(response => {
                if (!response.ok) throw new Error(`HTTP Error ${response.status}`)
                return response.json()
            })
            .catch(err => console.log(err))
            .then(rawData => statsFromApi(rawData.stats))
    }

    static getUserAccuracy(userId: number) {
        return fetch(ApiUrl.userAccuracy(userId))
            .then(response => {
                if (!response.ok) throw new Error(`HTTP Error ${response.status}`)
                return response.json()
            })
            .catch(err => console.log(err))
            .then(rawData => accuracyStatsFromApi(rawData.stats))
    }
}