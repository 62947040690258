import React, { useContext, useEffect, useState } from "react"
import { Badge, Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import Api from "api/Api"
import { IDeploymentStat } from "data/IDeploymentStat"
import UserContext from "UserContext"
import IUser from "data/IUser"


const DashboardPage = () => {
    const [stats, setStats] = useState<IDeploymentStat[]>()
    const nav               = useNavigate()
    const user              = useContext(UserContext) as IUser

    useEffect(() => {
        Api.getDeploymentStats()
            .then(stats => setStats(stats))
    }, [])

    const statsTable = () => (
        <table data-testid="deployment-stats-table"
               className="table table-striped w-auto dashboard-stats-table">
            <thead>
            <tr>
                <td>Deployment</td>
                <td>Active Users</td>
                <td>Incomplete</td>
                <td>Conflicts</td>
                <td>Checked Out</td>
                <td>Complete</td>
                <td>Total</td>
            </tr>
            </thead>
            <tbody>
            {stats?.map(stat =>
                <tr key={stat.uuid} data-testid="deployment-stats-table-row">
                    <td>
                        {stat.name}
                    </td>
                    <td>{stat.activeUsers}</td>
                    <td>{stat.incomplete}</td>
                    <td>{stat.conflicts}</td>
                    <td>{stat.checkedOut}</td>
                    <td>{stat.complete}</td>
                    <td>{stat.total}</td>
                </tr>
            )}
            </tbody>
        </table>
    )

    const sum = stats?.map(el => el.conflicts).reduce((a, b) => a + b, 0) ?? 0
    const badge = sum > 0 ? <Badge bg="danger" style={{top: "-2px"}}>{sum}</Badge> : <></>

    return (
        <div className="DashboardPage container">
            <h1>Dashboard</h1>
            <div className="my-5">
                <Button onClick={() => nav('/work')}
                        size="lg"
                        variant="outline-primary"
                        id="start-work-button">
                    Start Work!
                </Button>

                {user.isAdmin &&
                    <Button onClick={() => nav('/work?escalated=true')}
                            size="lg"
                            variant="outline-danger"
                            disabled={sum === 0}
                            style={{marginLeft: "1rem", display: "inline-block"}}
                            id="resolve-conflicts-button">
                        Resolve Conflicts {badge}
                    </Button>
                }
            </div>
            <>
                <h2>Task Stats by Deployment</h2>
                {stats ?  statsTable() : <p>Loading</p>}
            </>
        </div>
    )
}

export default DashboardPage