import React, { useEffect, useState } from 'react'
import Answer from "../data/IAnswer"
import Api from "../api/Api"
import ApiUrl from "../api/ApiUrl"

interface ImagePreloaderProps {
    work: Answer[]
}

const ImagePreloader = ({work}: ImagePreloaderProps) => {

    const [tracesToLoad, setTracesToLoad] = useState(new Set<string>())

    const loadCameraTraces = async (answer: Answer) => {
        const offset = answer.questionType === "start" ? -2 : 2
        const traceId = answer.questionType === "start" ? answer.startTrace : answer.endTrace
        setTracesToLoad(prev => new Set(prev.add(traceId)))
        const traces = await Api.getTracesByOffset(answer.cameraId, traceId, offset)
        setTracesToLoad(prev => new Set(prev.add(traces[0].traceId)))
        setTracesToLoad(prev => new Set(prev.add(traces[1].traceId)))
    }

    useEffect(() => {
        work.forEach((answer: Answer) => loadCameraTraces(answer))
    }, [])

    return (
        <div className="ImagePreloader">
            {Array.from(tracesToLoad).map((trace: string) => (
                <div style={{display: 'none'}} key={trace}>
                    <img src={ApiUrl.traceImgOriginal(trace)} alt={trace}/>
                </div>
            ))}
        </div>
    )
}

export default ImagePreloader