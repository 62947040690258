import "./WorkPage.css"
import Api from "api/Api"
import React, { useContext, useEffect, useRef, useState } from "react"
import User from "data/IUser"
import UserContext from "UserContext"
import QuestionViewer from "components/QuestionViewer"
import { Button, Col, ProgressBar, Row, Spinner } from "react-bootstrap"
import { DateTime } from "luxon"
import { useHotkeys } from "react-hotkeys-hook"
import { useNavigate, useSearchParams } from "react-router-dom"
import Answer from "data/IAnswer"
import ImagePreloader from "components/ImagePreloader"
import { toast, ToastContainer, Zoom } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

const UNDO_WINDOW       = 4000         // Milliseconds
const SAVE_QUIET_PERIOD = 5000         // Must exceed UNDO_WINDOW
const REDIRECT_DELAY    = 7000         // Must exceed SAVE_QUIET_PERIOD

const WorkPage = () => {
    const user                          = useContext(UserContext) as User
    const [isFinished, setIsFinished]   = useState<boolean>(false)
    const [work, setWork]               = useState<Answer[]>([])
    const [index, setIndex]             = useState<number>(0)
    const [startTime, setStartTime]     = useState<DateTime>()
    const saveTimers                    = useRef<Array<number | undefined>>([])
    const undoTimer                     = useRef<number | undefined>()
    const redirectTimer                 = useRef<number | undefined>()
    const nav                           = useNavigate()
    const [searchParams,]               = useSearchParams()

    const currentAnswer = work[index]

    const canUndo = () => !isOnFirstQuestion() && undoTimer.current !== undefined

    const disableUndo = () => undoTimer.current = undefined

    // This clears the timer on most recent save so that it's not submitted
    // Do not cancel the save if outside the undo window because could be already sent to server
    const cancelSave = () => clearTimeout(saveTimers.current.pop())

    const isOnFirstQuestion = () => index === 0

    const isOnLastQuestion = () => index === work.length - 1

    const loadNextQuestion = () => setIndex(index => index + 1)

    const loadPrevQuestion = () => setIndex(index => index - 1)

    const isWorkLoaded  = () => work.length > 0

    useEffect(() => {
        Api.getWork(user.userId, searchParams.get("escalated") === "true")
            .then(answers => setWork(answers))

        // Cleanup. Make sure to clear all timeouts on component unmount!
        return () => {
            saveTimers.current.forEach(ref => clearTimeout(ref))
            clearTimeout(undoTimer.current)
            clearTimeout(redirectTimer.current)
        }
    }, [])

    // This is far better than hooking in every place we call setIndex
    useEffect(() => {
        setStartTime(DateTime.now())
    }, [index])

    useHotkeys(['d'], (ev: KeyboardEvent) => {
        if (!isWorkLoaded() || isFinished) return
        denyAnswer(currentAnswer)
    })

    useHotkeys(['a'], (ev: KeyboardEvent) => {
        if (!isWorkLoaded() || isFinished) return
        approveAnswer(currentAnswer)
    })

    useHotkeys(['meta+z', 'ctrl+z'], (ev: KeyboardEvent) => {
        // Prevent default behavior of 'Command + Z' combo in some browsers
        ev.preventDefault()
        if (canUndo()) {
            disableUndo()
            cancelSave()
            if (isFinished) {
                // Stop the auto redirect so can go back to previous question
                clearTimeout(redirectTimer.current)
                // Hide the work finished screen
                setIsFinished(false)
            } else {
                loadPrevQuestion()
            }
            toast.info("Undone!", { autoClose: 2000 })
        } else {
            toast.error("Can no longer undo")
        }
    })

    const saveAnswer = (answer: Answer, timeTaken: number, approved: boolean) => {
        Api.saveAnswer(user.userId, answer.answerId, timeTaken, approved)
            .then(r => {
                if (r.status !== 200) throw new Error(`Expecting 200. Got ${r.status}`)
                console.log(`Answer ${answer.answerId} saved`)
            })
        toast.success('Saved!')
    }

    function handleButtonPress(answer: Answer, approved: boolean) {
        const timeDiff = DateTime.now().diff(startTime!, "milliseconds").milliseconds
        // Set 20s as the max time to answer a question. Prevents huge values when browser left open, which
        // can cause average times to be skewed
        const timeTaken = Math.min(timeDiff, 20000)

        // Use a timeout to allow undo. If the user changes their mind, we can cancel the save
        // use window.setTimeout (instead of setTimeout) because returns type 'number' | undefined
        const timer = window.setTimeout(() => saveAnswer(answer, timeTaken, approved), SAVE_QUIET_PERIOD)
        saveTimers.current.push(timer)

        // The minute you submit a new answer, you give up the opportunity to undo the previous question
        clearTimeout(undoTimer.current)
        // Start the undo window
        undoTimer.current = window.setTimeout(disableUndo, UNDO_WINDOW)

        if (isOnLastQuestion()) {
            setIsFinished(true)
        } else {
            loadNextQuestion()
        }
    }

    function approveAnswer(answer: Answer) {
        handleButtonPress(answer, true)
    }

    function denyAnswer(answer: Answer) {
        handleButtonPress(answer, false)
    }

    // Guard
    if (!isWorkLoaded()) {
        return <div className="WorkPage container"><p>Getting some work!</p></div>
    }

    function Viewer({answer}: { answer: Answer }) {
        const traceId = answer.questionType === "start" ? answer.startTrace : answer.endTrace

        return (
            <QuestionViewer
                traceId={traceId}
                event={answer.questionType}
                time={answer.startTimeUtc}
                bbox={answer.bbox}
                groundPlane={answer.groundPlane}
                domain="helios"
                classLabel={answer.classLabel}
                cameraId={answer.cameraId}
                answer={answer.answer}
                timezone={answer.tz}
            />
        )
    }

    const toastContainer = <ToastContainer
        position="top-right"
        autoClose={500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
        transition={Zoom}
    />

    if (isFinished || !isWorkLoaded()) {
        clearTimeout(redirectTimer.current)
        redirectTimer.current = window.setTimeout(() => nav("/"), REDIRECT_DELAY)

        return (
            <>
                <div className="WorkPage container">
                    <div className="work-complete">
                        <h1>
                            Work Complete! 🥳
                        </h1>
                        <div className="progress-box">
                            <Spinner className="spinner" animation="border" variant="secondary"/>
                            <p>Finishing Up</p>
                        </div>
                        <p className="footer">Please don't close this window!</p>
                    </div>
                </div>
                {toastContainer}
            </>
        )
    }

    return (
        <>
            <div className="WorkPage container">
                <div>
                    <div>
                        Viewing {searchParams.get('escalated') && "Escalated "}
                        Question <span data-testid="current-index">{index + 1}</span> of {work.length}
                    </div>
                    <div>
                        <ProgressBar now={(index + 1) * 100 / work.length}/>
                    </div>
                    <Viewer answer={currentAnswer}/>
                </div>
                <Row className="justify-content-center">
                    <Col md="auto">
                        <Button onClick={() => approveAnswer(currentAnswer)}>Approve (a)</Button>
                    </Col>
                    <Col md="auto">
                        <Button onClick={() => denyAnswer(currentAnswer)} variant="danger">Deny (d)</Button>
                    </Col>
                </Row>

                <ImagePreloader work={work}/>
            </div>
            {toastContainer}
        </>
    )
}

export default WorkPage
