import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'

import AppRoutes from './AppRoutes'

import 'bootstrap/dist/css/bootstrap.min.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
    //strict-mode breaks auth
    <AppRoutes />
)