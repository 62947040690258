import { DateTime } from "luxon"
import React, { createRef, useEffect, useState } from "react"
import ApiUrl from "api/ApiUrl"

export const sum = (arr: Array<number>) => arr.reduce((ps: number, a: number) => ps + a, 0)

export function convert2PointBoxTo4PointBox(asdf: number[][]) {
    let topLeft         = {x: asdf[0][0],       y: asdf[0][1]}
    let bottomRight     = {x: asdf[1][0],       y: asdf[1][1]}
    let topRight        = {x: bottomRight.x,    y: topLeft.y}
    let bottomLeft      = {x: topLeft.x,        y: bottomRight.y}
    return  [[topLeft.x,    topLeft.y],
            [topRight.x,    topRight.y],
            [bottomRight.x, bottomRight.y],
            [bottomLeft.x,  bottomLeft.y]
    ]
}

export function centroid(pnts: Array<Array<number>>) {
    let xs = sum(pnts.map(p => p[0]))
    let ys = sum(pnts.map(p => p[1]))
    return [xs / pnts.length, ys / pnts.length]
}

export interface IFrameObj {
    traceId:        string
    timeCaptured:   DateTime
    bbox:           Array<number[]>
    groundPoly:     Array<number[]>
    classLabel:     string
    label:          string
}

interface FrameObjViewerProps {
    obj:            IFrameObj
    color?:         string
    style?:         React.CSSProperties
    className?:     string
}

export default function FrameViewer(props: FrameObjViewerProps) {
    const [canvasRef] = useState<React.RefObject<HTMLCanvasElement>>(createRef<HTMLCanvasElement>())
    const [img, setImg] = useState<HTMLImageElement>()
    const fontIncr = 0

    useEffect(() => {
        processImage()
    }, [props.obj.traceId])

    async function processImage() {
        let bgImage = new Image()
        bgImage.src = ApiUrl.traceImgOriginal(props.obj.traceId)
        await new Promise<void>((resolve) => {
            bgImage.onload = (_e) => {
                return resolve();
            }
            bgImage.onerror = (_e) => {
                return resolve();
            }
        })
        setImg(bgImage)
    }

    function drawBoundingBoxes(ps: number[][],
                               ctx: CanvasRenderingContext2D) {
        if (ps.length === 0) {
            return
        }
        ctx.beginPath()
        ctx.moveTo(ps[0][0], ps[0][1])
        for (let i = 1; i < ps.length; i++) {
            ctx.lineTo(ps[i][0], ps[i][1])
        }
        ctx.lineTo(ps[0][0], ps[0][1])
        ctx.stroke()
    }

    function setTextStyle(ctx: CanvasRenderingContext2D,
                          fontName: string = "Monaco",
                          fontSize: number = 10,
                          fillColor: string = "black",
                          strokeColor: string = "black",
                          strokeWidth: number = 1,
                          alpha: number = 1.0) {
        ctx.font = `${fontSize}pt ${fontName}`
        ctx.fillStyle = fillColor
        ctx.strokeStyle = strokeColor
        ctx.lineWidth = strokeWidth
        ctx.globalAlpha = alpha
    }

    function setDrawStyle(ctx: CanvasRenderingContext2D,
                          fillColor: string = "black",
                          strokeWidth: number = 1,
                          strokeColor: string = "black",
                          alpha: number = 1.0) {
        ctx.fillStyle = fillColor
        ctx.strokeStyle = strokeColor
        ctx.lineWidth = strokeWidth
        ctx.globalAlpha = alpha
    }

    function drawCentroids(obj: IFrameObj, ctx: CanvasRenderingContext2D) {
        const cent = centroid(obj.bbox)
        if (!cent || cent.length === 0) {
            return
        }

        //draw centroid
        const [x, y] = [cent[0], cent[1]]
        setDrawStyle(ctx, "black", 5, "white")
        ctx.beginPath()
        ctx.arc(x, y, 2, 0, 2 * Math.PI)
        ctx.stroke()
        ctx.fill()

        //label centroid
        setTextStyle(ctx, "Monaco", 14 + fontIncr, "white", "black", 4)
        let vehicleType = obj.classLabel
        if (!vehicleType) {
            vehicleType = ""
        }
        const shortVehicleId = obj.label.toString()
        const text = shortVehicleId + " " + vehicleType
        ctx.strokeText(text, x + 8, y + 8)
        ctx.fillText(text, x + 8, y + 8)
    }

    function drawBoxes(obj: IFrameObj, color: string, ctx: CanvasRenderingContext2D) {
        setDrawStyle(ctx, "white", 2, color, 1.00)
        drawBoundingBoxes(convert2PointBoxTo4PointBox(obj.bbox), ctx)
    }

    function drawTimecode(desc: string, ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
        setTextStyle(ctx, "Monaco", 20, "white", "black", 4)
        ctx.strokeText(desc, 10, canvas.height - 10)
        ctx.fillText(desc, 10, canvas.height - 10)
    }

    const draw = (background: HTMLImageElement | undefined) => {
        if (!background) {
            return
        }
        const canvas = canvasRef.current
        if (!canvas) {
            return
        }
        canvas.height = background.naturalHeight
        canvas.width = background.naturalWidth
        const ctx = canvas.getContext("2d", {alpha: false})
        if (!ctx) {
            return
        }
        ctx.drawImage(background, 0, 0)
        const col = props.color ?? "white"
        drawBoxes(props.obj, col, ctx)
        // drawGroundPolygons( props.obj, ctx )
        drawCentroids(props.obj, ctx)
        drawTimecode(props.obj.label, ctx, canvas)
        return
    }

    return <>
        <canvas ref={canvasRef}
                className={props?.className}
                style={props?.style}/>
        {draw(img)}
    </>
}


